<template>
    <div class="elmec-select-global" :class="[theme, lblPosition]">
        <div class="container-label" v-if="label" :class="[lblPosition, {'disabled': disabled}]">
            <span :class="tooltip ? 'tooltip-style' : '' "
                  v-tooltip.top="{
                        content: tooltip
                      }"
            >{{label + ':'}}</span> <span class="required">{{required ? '*' : ''}}</span>
        </div>

        <div class="select-myelmec"
             :class="[status, {'selected_ellipsis': textEllipsis}, {'allow_empty': !allowEmpty},
                      {'hidden_tags':hideTags}, getInputPosition]">
            <vue-multiselect
                    ref="inp"
                    :options-limit="optLimit"
                    :options="selectOptions"
                    :name="name"
                    :label="labelName"
                    :track-by="valueName"
                    :value="valueName"
                    v-model="model"
                    :selectLabel="''"
                    :loading="isLoading"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :group-select="groupSelected"
                    :group-values="groupValues"
                    :group-label="groupLabel"
                    :openDirection="openDirection"
                    :multiple="multiple"
                    :allow-empty="allowEmpty"
                    :deselect-label="multiple ? 'Remove this value' : ''"
                    v-on:search-change="insertChar"
                    :show-no-results="showNoResult"
                    :internal-search="internalSearch"
                    :optionsLimit="optionsLimit"
                    :close-on-select="!hideTags"
                    :preserve-search="preserveSearch"
                    :isOpen="false"
                    @input="updateValue"
                    v-on:open="emitStatusSelect(true)"
                    v-on:close="emitStatusSelect(false)"
                    @open="openElement">

                <template v-if="hideTags" slot="selection" slot-scope="object">
                    <span v-if="!object.isOpen">Elementi selezionati: {{object.values.length}}</span>
                    <span v-else></span>
                </template>

                <template slot="noOption">
                    <span>Nessun elemento</span>
                </template>

                <template slot="placeholder">
                  <div class="myplaceholder__custom">
                    <img v-if="placeholderIcon" class="myplaceholder__img" :src="placeholderIcon" :alt="placeholder" height="25"/>
                    <span v-html="placeholder"/>
                  </div>
                </template>

                <template slot="singleLabel">
                  <img v-if="labelIcon" class="mysiglelabel__img" :src="labelIcon" height="25"/>
                  <span v-html="value"/>
                </template>

                <template slot="option" slot-scope="props">
                  <template
                      v-if="props.option['color'] || props.option['html'] || props.option['img']  || descriptionName">
                    <span v-if="props.option['color']" class="box-color"
                          v-bind:style="{ 'background-color' : props.option['color']}"></span>
                    <img v-if="props.option['img']" class="myoption__img" :src="props.option['img']" alt="logo"/>
                    <span class="myoption__desc">
                      <div class="myoption__custom" v-if="!props.option['html']">
                        <div class="myoption__title">{{ props.option[labelName] }}</div>
                        <div v-if="descriptionName" class="myoption__small">{{ props.option[descriptionName] }}</div>
                      </div>
                      <span v-else>
                        <span class="myoption__title" v-html="props.option[labelName]"></span>
                      </span>
                    </span>
                  </template>
                  <slot name="custom-option" v-bind="props" v-else></slot>
                </template>

                <template v-if="customCaret" slot="caret">
                  <img class="custom-caret" src="/img/devices2.svg" alt="logo"/>
                </template>
            </vue-multiselect>

        </div>
    </div>
</template>

<script>
  import VueMultiselect from 'vue-multiselect'

  require('vue-multiselect/dist/vue-multiselect.min.css')

  export default {
    name: 'Multiselect',
    components: {
      VueMultiselect
    },
    data: function () {
      return {
        model: null,
        selectOptions: []
      }
    },
    props: {
      tooltip: {type: String, default: ''},
      options: [Array, Object],
      label: {default: ''},
      groupSelected: {default: false},
      groupValues: {default: ''},
      groupLabel: {default: ''},
      eventName: {default: 'select_updated'},
      valueName: {default: ''},
      labelName: {default: ''},
      descriptionName: {default: ''},
      customCaret: {type: Boolean, default: false},
      preserveSearch: { type: Boolean, default: false },
      defaultValue: [String, Array, Number, Object],
      value: [String, Array, Number, Object],
      disabled: { type: Boolean },
      required: { type: Boolean },
      mandatory: { type: Boolean },
      internalSearch: { type: Boolean, default: true },
      name: { type: String },
      searchText: { type: String },
      search: { type: Boolean },
      multiple: { type: Boolean, default: false },
      showNoResult: { type: Boolean, default: true },
      close_on_select: { type: Boolean },
      placeholder: { type: String },
      placeholderIcon: { type: String },
      labelIcon: { type: String },
      clear_button: { type: Boolean },
      isLoading: { type: Boolean },
      optLimit: { type: Number, default: 100 },
      outputId: { type: Boolean, default: false },
      openDirection: { default: '' },
      allowEmpty: { type: Boolean, default: true },
      knowWhenIsOpen: { type: Boolean, default: false },
      optionsLimit: { type: Number, defuault: 100 },
      theme: { type: String, default: 'colored' },
      textEllipsis: { type: Boolean, default: false },
      lblPosition: { type: String, default: 'top' },
      hideTags: {type: Boolean, default: false},
      status: {
        type: String,
        default: '',
        validator: function (value) {
          return ['', 'warning', 'error'].indexOf(value) !== -1
        }
      },
      imgSize: {
        type: Number,
        default: 1.0
      },
      addNewItem: {type: Boolean, default: false},
      addNewItemText: {type: String, default: 'Add new item'},
      full: {type: Boolean, default: false} /* emit fullInput event with all options data, not only v-model value */
    },
    mounted: function () {
      if (Array.isArray(this.defaultValue)) {
        let tmpArr = []
        this.options.forEach(o => {
          this.defaultValue.forEach(v => {
            if (o[this.valueName] === v) {
              tmpArr.push(o)
            }
          })
        })
        this.model = tmpArr
      } else {
        let objFound = this.options.filter((obj) => {
          return obj[this.valueName] === this.defaultValue
        })
        this.model = objFound[0]
      }
      this.selectOptions = this.options
      if (this.addNewItem) {
        this.selectOptions.unshift({val: this.addNewItemText, label: this.addNewItemText, icon: 'my-add'})
      }
    },
    watch: {
      value: function (val) {
        this.$emit(this.eventName, this.value)
        if (Array.isArray(val)) {
          let tmpArr = []
          this.selectOptions.forEach(o => {
            val.forEach(v => {
              if (o[this.valueName] === v) {
                tmpArr.push(o)
              }
            })
          })
          this.model = tmpArr
        } else {
          let objFound = this.selectOptions.filter((obj) => {
            return obj[this.valueName] === val
          })
          this.model = objFound[0]
        }
      },
      options: {
        handler() {
          if (Array.isArray(this.defaultValue)) {
            let tmpArr = []
            this.options.forEach(o => {
              this.defaultValue.forEach(v => {
                if (o[this.valueName] === v) {
                  tmpArr.push(o)
                }
              })
            })
            this.model = tmpArr
          } else {
            let objFound = this.options.filter((obj) => {
              return obj[this.valueName] === this.defaultValue
            })
            this.model = objFound[0]
          }
          this.selectOptions = this.options
          if (this.addNewItem) {
            this.selectOptions.unshift({val: this.addNewItemText, label: this.addNewItemText, icon: 'my-add'})
          }
        },
        deep: true
      }
    },
    methods: {
    insertChar: function (value) {
      this.$emit('modifySearch', value)
    },
    updateValue: function (value) {
      if (this.addNewItem && value.label ===  this.addNewItemText) {
        this.model = null
        this.$emit('clickNewItem')
      } else if (Array.isArray(value)) {
        let arr = []
        value.forEach(v => {
          arr.push(v[this.valueName])
        })
        this.$emit('input', arr)
      } else {
        let val = (value === null ? null : value[this.valueName])
        this.$emit('input', val)
      }

        if (this.full) {
          this.$emit('fullInput', value)
        }
      },
      openElement: function () {
        this.$emit('open_smartselect')
      },
      emitStatusSelect: function (isOpen) {
        if (this.knowWhenIsOpen) {
          this.$emit('isSelectOpen', isOpen)
        }
      }
    },
    computed: {
      getInputPosition: function () {
        let position = ''

        if (this.lblPosition === 'top') {
          position = 'bottom'
        } else if (this.lblPosition === 'bottom') {
          position = 'top'
        } else if (this.lblPosition === 'left' || this.lblPosition === 'left-form') {
          position = 'right'
        } else if (this.lblPosition === 'right') {
          position = 'left'
        }

        return position
      }
    }
  }
</script>

<style lang="less">

  .elmec-select-global {
    width: 100%;
    float: left;
    position: relative;
    display: flex;

    .select-myelmec {
      cursor: pointer;

      &.hidden_tags {
        z-index: 25;

        .multiselect__content-wrapper {
          position: relative;
        }

        .multiselect__tags {
          display: block;
          padding-top: 5px !important;
          color: #33475b;
        }

        .multiselect--active {
          .multiselect__tags {
            padding-top: 2px !important;
          }
        }

        .multiselect__content-wrapper {
          max-height: 200px !important;
        }

        .multiselect__placeholder {
          display: none !important;
        }
      }

      &.selected_ellipsis {
        .multiselect__tags {
          width: 100%;
          cursor: pointer;

          .multiselect__single {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 90%;
          }
        }
      }

      &.allow_empty {
        .multiselect__option--selected:after {
          content: '' !important;
        }
      }

      &.top, &.bottom {
        width: 100%;
        float: left;
      }

      &.left {
        min-width: 60%;
        float: left;
      }

      &.right {
        min-width: 60%;
        float: right;
      }

      &.warning {
        .multiselect {
          .multiselect__tags {
            border: 1px solid orange;
            box-shadow: 0 0 3px orange;
          }
        }
      }

      &.error {
        .multiselect {
          .multiselect__tags {
            border: 1px solid #CB1229 !important;
            box-shadow: 0 0 3px #dd102a80 !important;
          }
        }
      }

      .box-color {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: green;
        margin-right: 5px;
      }

      .smart-select-icon {
        margin-right: 5px;
      }

      .multiselect {
        padding-left: 0 !important; // we need this to overwrite width and padding-left assign to this element
        width: auto !important; // we need this to overwrite width and padding-left assign to this element

        &.multiselect--active {
          .multiselect__select {
            visibility: hidden;
            display: inline-block;
          }
        }

        .multiselect__select {
          transition: 0s;
        }

        .multiselect__single {
          display: flex;
          align-items: center;

          .mysiglelabel__img {
            margin-right: 10px;
            max-height: 20px;
            max-width: 30px;
          }
        }

        .multiselect__option {
          display: flex;
          align-items: center;
        }

        .myoption__img {
          margin-right: 10px;
          max-height: 20px;
          max-width: 30px;
        }

        .myoption__desc {
          .myoption__custom {

            .myoption__title {
              flex-wrap: wrap;
            }

            .myoption__small {
              flex-wrap: wrap;
            }
          }
        }

        .custom-caret {
          color: #0082A0;
          position: absolute;
          right: 7px;
          top: 7px;
        }

      }

      .multiselect__placeholder {
        margin-bottom: 0;
        padding: 3px 0 0 0;
        color: #33475b;
        font-weight: lighter;

        .myplaceholder__custom {
          display: flex;
          align-items: center;

          .myplaceholder__img {
            max-height: 20px;
            max-width: 30px;
            margin-right: 5px;
          }
        }
      }

      .multiselect__tags-wrap {
        align-items: center;
        display: flex;
        margin-bottom: 3px;
        cursor: pointer;
      }

      .multiselect__spinner {
        background-color: transparent;

        &::before {
          left: 58%;
        }

        &::after {
          left: 58%;
        }
      }

      .multiselect__tag {
        margin-bottom: 0;
      }

      .multiselect__single {
        color: #33475b;
        padding: 2px 0 0 0;
        margin: 0;
        background: transparent;
      }

      .multiselect__single:after {
        color: #33475b;
        padding-left: 0;
        padding-bottom: 0;
        margin: 0;

        background: transparent;
      }

      .multiselect__input {
        color: #33475b;
        padding: 2px 0 0 0;
        margin: 0;
        background: transparent;
      }

      .multiselect__input:after {
        color: #33475b;
        padding-left: 0;
        padding-bottom: 0;
        margin: 0;
      }

      .multiselect__option {
        padding: 6px;
        border-bottom: none;
        min-height: 30px;
        color: #33475b;
      }

      .multiselect__option--highlight {
        background: #C9E1E7;
        outline: none;
        color: #33475b;
      }

      .multiselect__option--highlight:after {
        content: attr(data-select);
        background: #C9E1E7;
        color: #33475b;
      }

      .multiselect__option--selected {
        background: #2C5693;
        color: #FFFF;
        font-weight: unset;
      }

      .multiselect__option--selected:after {
        content: 'x' !important; // here is your X(cross) sign.
        color: #fff;
        font-weight: 300;
        font-family: Arial, sans-serif;
      }

      .multiselect__option--highlight {
        &.multiselect__option--selected:after {
          background: transparent;
          color: #FFFF;
          height: 29px;
          line-height: 30px;
        }
      }

      .multiselect__option--selected:after {
        background: transparent;
        color: #FFFF;
        height: 29px;
        line-height: 30px;
      }

      .multiselect__option--selected.multiselect__option--highlight {
        background: #2C5693;
        color: #FFFF;
      }

      .multiselect__option--selected.multiselect__option--highlight:after {
        background: #2C5693;
        content: attr(data-deselect);
        color: #FFFF;
      }

      .multiselect--disabled .multiselect__select {
        background: transparent;
      }

      .multiselect__select {
        position: absolute;
        /*width: 18px;
        padding: 0;*/
      }

      .multiselect {
        .multiselect__content-wrapper {
          box-shadow: 0 0 5px #999;
          width: 100%;
          max-height: 180px !important;
        }
      }

      .multiselect__content-wrapper::-webkit-scrollbar-track {
        background-color: #FFFF;
      }

      .multiselect__content-wrapper::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        background-color: #2C5693;
      }

      .multiselect__content-wrapper::-webkit-scrollbar-thumb {
        background-color: #2C5693;
      }

      select:disabled {
        background-color: #F0F0F0;
        border-bottom: 1px solid #B8B8B8;
      }

      .icon-dropdown-myelmec {
        display: inline-block;
        position: absolute;
        right: 15px;
        bottom: -5px;

        svg {
          color: #2C5693;
        }
      }
    }

    &.top {
      flex-direction: column;
    }

    &.bottom {
      flex-direction: column-reverse;
    }

    &.left {
      flex-direction: row;
      align-items: center;
    }

    &.right {
      flex-direction: row-reverse;
      align-items: center;
    }

    &.colored {
      .select-myelmec {
        .multiselect {
          min-height: 20px;

          .multiselect__select {
            top: -4px;
          }

          .multiselect__tags {
            border: none;
            border: 1px solid #C4CBCC;
            border-radius: 5px;
            background: #F4F8FB;
            min-height: 16px;
            height: 30px;
            padding: 2px 10px;
          }
        }
      }
    }

    &.ghost, &.no-label-ghost {
      .select-myelmec {
        height: 20px;

        &.selected_ellipsis {
          .multiselect {
            .multiselect__select {
              position: absolute;
            }
          }
        }

        .multiselect {
          display: flex;
          height: 20px;

          .multiselect__select {
            top: -7px;
            order: 2;
            position: relative;
            float: left;
            transition: 0s;
          }

          .multiselect__tags {
            border: none;
            border-radius: 5px;
            min-height: 16px;
            padding: 0 10px 2px 0;;
            order: 1;
          }

          .multiselect__content-wrapper {
            margin-top: 25px;
          }
        }
      }

      .container-label {
        padding-top: 3px;
      }
    }

    &.vertical-ghost {
      .container-label {
        height: 16px;
        margin-bottom: 0;
      }

      .select-myelmec {
        .multiselect__tags {
          height: 20px;
          padding: 0 10px 2px 0;
        }
      }
    }

    &.no-label-ghost {
      .select-myelmec {
        height: 20px;
        width: 100%;

        .multiselect {
          height: 20px;
          min-height: 20px;
        }
      }
    }

    &.search-icon-left-rounded {
      .select-myelmec {
        height: 50px;

        .multiselect {
          //min-height: 20px;

          .multiselect__select {
            top: -4px;
          }

          .multiselect__tags {
            border-radius: 12px;
          }

          .multiselect__input {
            margin-top: 2px;
          }

          .custom-caret {
            color: dark#33475b;
            position: absolute;
            left: 10px;
            top: 15px;
          }

          .multiselect__content-wrapper {
            border-radius: 0px;
          }
        }
      }
    }

    &.rounded {
      .select-myelmec {
        height: 35px;
        border-top: 1px solid #C4CBCC;
        border-right: 1px solid #C4CBCC;
        border-bottom: 1px solid #C4CBCC;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        &.selected_ellipsis {
          .multiselect {
            .multiselect__select {
              position: absolute;
            }
          }
        }

        .multiselect {
          display: flex;
          height: 20px;
          padding-top: 3px;

          .multiselect__select {
            top: -7px;
            order: 2;
            position: relative;
            float: left;
            transition: 0s;
          }

          .multiselect__tags {
            border: none;
            border-radius: 5px;
            min-height: 16px;
            width: 100%;
            height: inherit;
            padding: 0 10px 2px 0;;
            order: 1;
          }

          .multiselect__content-wrapper {
            margin-top: 25px;
          }
        }
      }

      .container-label {

        &.left {
          padding: 5px 10px;
          height: 35px;
          line-height: 22px;
          border-top: 1px solid #C4CBCC;
          border-left: 1px solid #C4CBCC;
          border-bottom: 1px solid #C4CBCC;
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px;
          margin-right: 0;
        }
      }
    }

    &.small-rounded {
      .select-myelmec {
        height: 35px;
        border-top: 1px solid #C4CBCC;
        border-right: 1px solid #C4CBCC;
        border-bottom: 1px solid #C4CBCC;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        &.selected_ellipsis {
          .multiselect {
            .multiselect__select {
              position: absolute;
            }
          }
        }

        .multiselect {
          display: flex;
          height: 20px;
          padding-top: 5px;

          .multiselect__select {
            top: -7px;
            order: 2;
            position: relative;
            float: left;
            transition: 0s;
          }

          .multiselect__tags {
            background: transparent;
            border: none;
            border-radius: 5px;
            min-height: 16px;
            width: 100%;
            height: inherit;
            padding: 0 10px 2px 0;;
            order: 1;
          }

          .multiselect__single {

          }

          .multiselect__content-wrapper {
            margin-top: 25px;
          }

          .multiselect__input {
            font-size: 12px;
            margin-top: 1px;
            height: 15px;
          }
        }
      }

      .container-label {

        &.left {
          padding: 5px 10px;
          height: 35px;
          line-height: 22px;
          border-top: 1px solid #C4CBCC;
          border-left: 1px solid #C4CBCC;
          border-bottom: 1px solid #C4CBCC;
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px;
          margin-right: 0;
        }
      }
    }

    &.rounded {
      .select-myelmec {
        height: 35px;
        border-top: 1px solid #C4CBCC;
        border-right: 1px solid #C4CBCC;
        border-bottom: 1px solid #C4CBCC;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        &.selected_ellipsis {
          .multiselect {
            .multiselect__select {
              position: absolute;
            }
          }
        }

        .multiselect {
          display: flex;
          height: 20px;
          padding-top: 2px;

          .multiselect__select {
            top: -4px;
            order: 2;
            position: relative;
            float: left;
            transition: 0s;
          }

          .multiselect__tags {
            background: transparent;
            border: none;
            border-radius: 5px;
            min-height: 16px;
            width: 100%;
            height: inherit;
            padding: 0 10px 2px 0;;
            order: 1;
          }

          .multiselect__content-wrapper {
            margin-top: 25px;
          }

          .multiselect__placeholder {
            font-size: 16px;
          }

          .multiselect__input {
            font-size: 16px;
            margin-top: 0px;
            height: 25px;
          }

          .multiselect__single {
            margin-top: 2px;
            font-size: 16px;
          }

          .multiselect__option {
            font-size: 16px;
          }
        }
      }

      .container-label {

        &.left {
          font-size: 16px;
          padding: 5px 10px;
          height: 35px;
          line-height: 22px;
          border-top: 1px solid #C4CBCC;
          border-left: 1px solid #C4CBCC;
          border-bottom: 1px solid #C4CBCC;
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px;
          margin-right: 0;
        }
      }
    }

    .container-label {
      text-align: left;
      display: block;
      letter-spacing: 1px;
      line-height: 20px;
      height: 20px;
      color: #33475b;

      .tooltip-style {
        &:hover {
          text-decoration: underline;
          cursor: help;
        }
      }

      &.left {
        margin-right: 10px;
        float: left;
        max-width: 40%;
        height: auto;
        color: #33475b;

        .required {
          top: 2px;
        }
      }

      &.right {
        margin-left: 10px;
        float: right;
        height: auto;
        color: #33475b;

        .required {
          top: 2px;
        }
      }

      &.top, &.bottom {
        width: 100%;
        float: left;
        height: 20px;

        .required {
          top: -5px;
        }
      }

      &.disabled {
        opacity: 0.6;
        color: gray !important;
      }

      .required {
        position: absolute;
        color: #33475b;
        font-weight: bold;

        &.disabled {
          opacity: 0.6;
          color: #33475b;
          font-weight: bold;
        }
      }

      &.left-form {
        margin-right: 10px;
        float: left;
        width: 40%;
        height: auto;
        color: #33475b;

        .required {
          top: 2px;
        }

        &.disabled {
          opacity: 0.6;
          color: #33475b;
        }
      }
    }
  }
</style>
